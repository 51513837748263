$white: #fff;
$dark-blue: #425672;
$border-radius: 6px;
$blue: #0079bf;
//$blue: #387eb6;

$light-blue: #e3ebf7;
$body-bg: #f2f7fb;
$header-color: #47495a;

$green: #54973f;
//$green: #54973F;

//$text-color: #6b7c93;
$text-color: #3b3b3b;
$blue-2: #00b4ff;
$red: #ff7677;
$gradient: linear-gradient(89deg, #4CB6FF 15%, #4DA3FF 100%);
$box-shadow: 0 4px 9px 0 rgba(23, 28, 33, .06);


//$gray: #f5f5f5;
//$gray: #e3e8eb;

//austin
//$gray: #f2f7fb;


$gray: #e3e8eb;

//1
$c-hero: #BFD8D2;
$c-footer: white;
$c-cta: #DF744A;
//$c-cta: #DCB239;


//2


$c-cta: #EFD9C1;
$c-hero: #A9B7C0;
//$c-hero: #C7D8C6;
$c-footer: white;

//3

$c-cta: #fce181;
$c-hero: #026670;
//$c-hero: #C7D8C6;

$c-footer: white;


////4
//$c-g-1: #edeae5;

$c-hero: #a7cfdd;
//$c-hero: #fedcd2;
$c-cta: #f8ce6a;

//$c-footer: #363636;
$c-footer: #a7cfdd;
$c-cta: white;
$c-g-1: #efefef;


//toggle


$c-1: #f8ce6a;
$c-2: #ffacba;
$c-3: #91D8FF;
$c-4: #ca99d7;



