.btn {
  font-weight: 800;
  //font-size: 13px;
  font-family: "Avenir Next H", "Avenir Next", "Avenir", helvetica, arial, sans-serif;
  //text-transform: uppercase;
  transition: .2s ease-in;

  vertical-align: middle;
  text-align: center;
  white-space: normal;
  font-size: 1.1em;

  padding: .4375em 1.5em .5em;

  &.btn--lg {
    padding: 14px 40px;
  }

  &.btn--md {
    padding: 12px 25px;
  }

  &.btn--cta {
    background: $c-cta;
    border-color: $c-cta;
    //color: $white;
    color: #3b3b3b;
    font-weight: 400;
    &:hover {
      background: darken($c-cta, 5%)
    }
  }

  &.btn--secondary {
    background: $dark-blue;
    border-color: $dark-blue;
    color: $white;

    &:hover {
      background: darken($dark-blue, 5%)
    }
  }

  &.btn--primary {
    background: $light-blue;
    border-color: $light-blue;
    color: $blue;

    &:hover {
      background: lighten($light-blue, 1.5%);
      color: lighten($blue, 2%);
    }
  }

  &.btn-third {
    background: transparent;
    border: 2px solid $white;
    color: $white;

    &:hover {
      background: $white;
      color: $green;
    }
  }

  &.btn--green {
    background: $green;
    border-color: $green;
    color: $white;

    &:hover {
      background: darken($green, 5%)
    }
  }

  &.btn--blue {

    $cool-blue: #387eb6;
    background: $cool-blue;
    border: 2px solid $cool-blue;
    color: $white;

    &:hover {
      background: darken($cool-blue, 2%);
      color: $cool-blue;
    }
  }

  //&.btn--orange {
  //
  //  color: $white;
  //  background: $c-orange;
  //  border-color: $c-orange;
  //
  //  &:hover {
  //    background-color: darken(, 5%);
  //    border-color: darken($c-orange, 5%);
  //    color: $white;
  //  }
  //
  //}

  &.btn--white {
    background: transparent;
    border: 2px solid $white;
    color: $white;

    &:hover {
      background: $white;
      color: black;
    }
  }

  &.btn--gray {
    background: transparent;
    border: 2px solid darken($gray, 7%);
    color: $text-color;

    &:hover {
      background: $gray;

    }
  }
}