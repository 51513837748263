.architecture {
  padding-top: 40px;

  .with--margin {
    margin-top: 60px;

    @media (max-width: 991px) {
      margin-top: 0;
    }
  }

  .feature-card {
    padding: 30px;
    margin-bottom: 30px;
    background: $white;
    border-radius: $border-radius;
    box-shadow: $box-shadow;

    &:last-child {
      margin-bottom: 0;
    }

    i {
      display: block;
    }
  }
}

