$base-url: "../css/";

@font-face {
  font-family: "Avenir Next H";
  font-weight: 600;
  src: url($base-url + "fonts/avenir_next_medium/Font.eot?#iefix");
  src: url($base-url + "fonts/avenir_next_medium/Font.eot?#iefix") format("eot"),
url($base-url + "fonts/avenir_next_medium/Font.woff2") format("woff2"),
url($base-url + "fonts/avenir_next_medium/Font.woff") format("woff"),
url($base-url + "fonts/avenir_next_medium/Font.ttf") format("truetype"),
url($base-url + "fonts/avenir_next_medium/Font.svg") format("svg")
}

@font-face {
  font-family: "Avenir Next H";
  font-weight: 700;
  src: url($base-url + "fonts/avenir_next_demi/Font.eot?#iefix");
  src: url($base-url + "fonts/avenir_next_demi/Font.eot?#iefix") format("eot"),
url($base-url + "fonts/avenir_next_demi/Font.woff2") format("woff2"),
url($base-url + "fonts/avenir_next_demi/Font.woff") format("woff"),
url($base-url + "fonts/avenir_next_demi/Font.ttf") format("truetype"),
url($base-url + "fonts/avenir_next_demi/Font.svg") format("svg")
}


@font-face {
  font-family: "Avenir Next H";
  font-weight: 800;
  src: url($base-url + "fonts/avenir_next_bold/Font.eot?#iefix");
  src: url($base-url + "fonts/avenir_next_bold/Font.eot?#iefix") format("eot"),
url($base-url + "fonts/avenir_next_bold/Font.woff2") format("woff2"),
url($base-url + "fonts/avenir_next_bold/Font.woff") format("woff"),
url($base-url + "fonts/avenir_next_bold/Font.ttf") format("truetype"),
url($base-url + "fonts/avenir_next_bold/Font.svg") format("svg")
}