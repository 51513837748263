#newsletterModal {

  .modal-content {
    background: transparent;
    border-radius: 0;
    border: none;

    .close {
      position: absolute;
      right: 30px;
      top: 30px;
      color: $white;
      z-index: 1;
    }

    .modal-body {
      padding: 40px 50px 120px 50px;
      background: url(../images/modal.jpg) no-repeat top;
      background-size: cover;
      border-radius: $border-radius;

      &:before {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: rgba(0,0,0,.1);
        content: "";
      }

      .modal__header {
        color: $white;
      }

      .modal__text {
        color: rgba(255,255,255,.8);
      }

      .modal__form {
        width: 90%;

        &--input {
          height: 45px;
        }

        &--btn {
          padding: 10px;
          height: 45px;
        }
      }
    }
  }
}