@import "austin/main";



.box-shadow {
  box-shadow: 0 7px 14px 0 rgba(50,50,93,.1), 0 3px 6px 0 rgba(0,0,0,.07) !important;
}

.section-gray {
  background-color: $c-g-1;
  //border-top: 10px solid white;
}

.footer {
  background-color: $c-footer;
  position: relative;
  color: white;

  > h4 {
    color: white !important;
  }

}

.second-footer {
  background-color: darken($c-footer, 10%);
  position: relative;
  color: white;
}

.footer-widget__nav {
  > li > a {
    color: #9E9E9E !important;
    &:hover {
      color: $blue !important;
    }
  }
}

.footer-logo {
  width: 120px;
  z-index: 1000;
  position: absolute;
  bottom: 20px;
  right: 5%;
}

.footer-link {
  color: inherit !important;
  font-size: 1.4em;
  line-height: 40px;
  font-weight: 900;
  &:hover {
    color: white;
    text-decoration: underline;
  }
  display: block;
}

.footer-title {
  color: #47495a !important;
  margin-bottom: 3%;

}

.btn-main-page {

  background-color: $c-hero !important;
  //border-color: $c-orange;
  border: 1px solid currentColor;
  color: white;
  font-weight: 400;
  padding: 12px 30px !important;

  //&:hover {
  //  background-color: $c-orange !important;
  //  color: $c-hero;
  //  border-color: $c-orange;
  //  //font-weight: 9;
  //}
}

h3 {
  color: $c-hero !important;
}

section > .preamble {
  margin-bottom: 0.7125em !important;
}

.nav-bar {
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 2;
  background-color: $c-hero;
  transition: all .2s ease;
}

.nav-bar-invisible {
  top: -40px;
  opacity: 0;
  transition: all .2s ease;
}

.btn-nav-bar {
  background-color: transparent !important;
  font-weight: 900;
  font-size: 0.9em;
  z-index: 3;
  border-color: transparent !important;

  &:hover {
    color: $c-hero !important;
  }
}

.nav-bar-right-container {
  text-align: right;
  position: absolute;
  right: 10px;
  top: 0px;
  width: 100%;
  padding: 5px 15px !important;
}

.nav-bar-menu-icon {
  width: 50px;
  z-index: 7 !important;
}

.nav-bar-logo {
  width: 100px;
}

.hero-logo {
  width: 90%;
  max-width: 500px;
}

.fullscreen-menu {
  position: fixed;
  top: 0;
  z-index: 999;
  background-color: $c-hero;
  width: 100%;
  height: 100%;
  text-align: left;
  //border-bottom: 5px solid $c-orange;
  //border-left: 5px solid $c-orange;
  transition: all .2s ease;
  opacity: 1;

}

.menu-invisible {
  top: -100%;
  opacity: 0;
  transition: all .2s ease;

}

.menu-item {

  color: white !important;
  > a {
    color: inherit !important;
  }

}

.fullscreen-menu-logo-container {
  position: absolute;

  bottom: 0;
  background-color: darken($c-hero, 3%);
  width: 100%;
}

.fullscreen-menu-logo {
  margin-left: auto;
  margin-right: auto;
  width: 70%;
  max-width: 300px;
  display: block;

}

.close-menu {
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-right: 20px;
  cursor: pointer;
  background-color: darken($c-hero, 3%);
  text-align: right;
}


.header-dark {
  //color: $c-hero;
  color: #2b2b2b;
}



.bottom-cta-container {
  padding-top: 6px;
  position: fixed;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 50px;
  background-color: $c-g-1;



}
.call-button {
  position: fixed;
  bottom: 0;
  right: 0;
}

.cta-button {
  background-color: white;
  color: black;
  padding: 7px 3px 7px 3px;
  border-radius: 7px;
  max-width: 250px;
  //margin-left: auto;
  margin-right: auto;
  display: block;
  cursor: pointer;
}

.hero-header {
  //color: black;
  background-color: $c-hero !important;
  color: white;
}

.company-divider {
  background-color: $c-hero !important;
  width: 5px;
  margin-left: 3px;
  margin-right: 3px;
}
.company-info {
  font-size: 0.9em;
  line-height: 20px;
  color: $c-g-1;
}

.hero-image-saskia {
  border-radius: 40px;
  margin-top: -50px;

  width: 200px;
}

.hero-image-beeldje {
  border-radius: 10px;
  margin-top: -50px;

  width: 200px;
}

.top-bar {
  position: fixed;
  top: 0;
  left: 0;
  background-color: darken($c-footer, 5%);
  width: 100%;
  height: 45px;

  padding-top: 10px;
  //padding-right: 20px;
  //border-bottom: 2px solid white;

  >.menu-link {
    display: inline;
    color: #eeeeee;
    //margin-left: 50px;
    margin-left: 25px;
    margin-right: 25px;

  }
}

.btn {
  border-radius: 10px !important;
}