/* Table of Content

- variables;
- scaffolding;
- shortcodes;
- typography;
- buttons;
- modal;
- navbar;
- hero;
- features;
- pricing;
- contact;
- testimonials;
- cta;
- footer;
- clients;
- services;
- locations;
- integrations;
- charts;
- faq;
- tour;
- app;
- promo;
- about;
- transfer;

*/


@import "modules/all";
@import "partials/base";
@import "styling/all";

.tour__image {
  max-width: 80%;
  margin-left: auto;
  margin-right: auto;
  display: block;
}
.round {
  border-radius: 50px !important;
}

.section--light {
  background-color: #f2f7fb;
}

.icon-red {
  color: red !important;
}

.box-shadow {
  box-shadow: 0 15px 35px rgba(50,50,93,.1), 0 5px 15px rgba(0,0,0,.07);
}

.epic-image-left {
  box-shadow: -1px -1px 5px 0 rgba(26,26,67,.05), -12px 20px 125px -25px rgba(50,50,93,.5), -18px 20px 75px -37.5px rgba(0,0,0,.6);
  transform: scale(1) perspective(3000px) rotateY(15deg) rotateX(3deg) rotate(-3deg);
}


.gradient-main {
  //background: linear-gradient(to right bottom, $c-hero, #00629b, #0079bf, #05d5ff);
  background: linear-gradient(to right bottom, $c-hero, #0079bf);
}
.gradient-light {
  background: linear-gradient(-180deg, $body-bg 100%, $white 30%);
}

.nav-item {
  animation-duration: 400ms !important;
}

