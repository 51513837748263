
.intro {
  position: relative;
  padding-top: 80px;
  margin-bottom: 40px;

  @media (max-width: 1199px) {
    padding-top: 60px;
  }

  > .container {
    //transform: translateY(-25%);

    margin-top: -200px;

    .feature-card {
      padding: 50px;
      background: $white;
      box-shadow: $box-shadow;
      border-radius: $border-radius;

      @media (max-width: 767px) {
        padding: 30px;
      }

      li {
        padding: 4px;
        display: block;

        .feature-card__icon {
          margin-right: 1rem;
          color: $green;
          font-size: 18px;
          font-weight: bold;
        }
      }
    }
  }


}