#features {

  &.features-1 {

    .feature {
      margin-bottom: 30px;
    }
  }

  &.features-2 {
    padding-bottom: 120px;

    img {
      max-width: 80%;
    }

    p:not(.lead) {
      margin-bottom: 0;
    }
  }

  &.features-3 {
    padding: 30px 0 30px 0;
    background:  linear-gradient(to right bottom, #111e35, #12213c, #2b446f, #549ba7);

    .feature-option {
      position: relative;
      padding: 100px 65px 0 65px;
      text-align: center;
      min-height: 450px;

      &:before {
        position: absolute;
        width: 2px;
        content: "";
      }

      &--first {

        &:before {
          right: 0;
          top: 0;
          height: 100%;
          background: linear-gradient(to bottom, rgba(150,150,150,0) 20%,rgba(255,255,255,.4) 80%, rgba(255,255,255,.8) 100%);
        }
      }

      &--second {

        &:before {
          right: 0;
          bottom: -2px;
          height: 2px;
          width: calc(100% + 30px);
          background: linear-gradient(to left, rgba(150,150,150,0) 20%,rgba(255,255,255,.4) 80%, rgba(255,255,255,.8) 100%);
        }
      }

      &--third {

        &:before {
          left: -2px;
          top: 0;
          height: 2px;
          width: 100%;
          background: linear-gradient(to right, rgba(150,150,150,0) 20%,rgba(255,255,255,.4) 80%, rgba(255,255,255,.8) 100%);
        }
      }

      &--fourth {

        &:before {
          top: 0;
          left: -32px;
          height: 100%;
          background: linear-gradient(to top, rgba(150,150,150,0) 20%,rgba(255,255,255,.4) 80%, rgba(255,255,255,.8) 100%);
        }
      }

      svg {
        stroke: $blue-2;
        height: 80px;
        width: 80px;
      }

      h5 {
        color: $white;
      }

      p {
        margin-bottom: 0;
        color: rgba(255,255,255,.8);
      }
    }
  }

  &.features-4 {
    padding: 120px 0;

    > .container {

      > .row {
        margin-bottom: 120px;

        &:last-child {
          margin-bottom: 0;
        }

        .preamble {
          margin-bottom: 60px;
        }
      }
    }
  }

  &.features-5 {
    padding: 0;
    background: $white;

    .features {

      li {
        margin-bottom: 1.5rem;
        display: block;

        &:last-child {
          margin-bottom: 0;

          p {
            margin-bottom: 0;
          }
        }
      }
    }
  }

  &.features-6 {

    .feature {

      &__image {
        margin: 0 auto 60px auto;
        max-width: 80%;

        @media (max-width: 991px) {
         max-width: 100%;
        }
      }
    }
  }

  &.features-7 {
    padding-top: 40px;

    .with--margin {
      margin-top: 60px;

      @media (max-width: 991px) {
        margin-top: 0;
      }
    }

    .feature-card {
      padding: 30px;
      margin-bottom: 30px;
      background: $white;
      border-radius: $border-radius;
      box-shadow: $box-shadow;

      &:last-child {
        margin-bottom: 0;
      }

      i {
        display: block;
      }
    }
  }
}