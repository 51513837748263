@media only screen and (max-width: 450px) {

  .navbar {
    background-color: transparent !important;
    box-shadow: none !important;
  }
  .navbar-brand {
    display: none;

  }
  .mobile-menu {
    background-color: white !important;
    position: fixed;
    top: -3%;
    width: 100%;
    left: 0;
    padding-top: 20%;
    padding-bottom: 10%;
    font-size: 200%;
    padding-left: 10%;
    text-align: left;
    box-shadow: 0 0 10px 10px rgba(0, 0, 0, 0.15);
    height: 105% !important;


  }

  .top-menu {
    background-color: transparent;
    box-shadow: none !important;
  }

  a.nav-link {
    font-size: 120% !important;
    line-height: 150%;

    &.nav-link__cta {
      position: absolute !important;
      bottom: 7% !important;
      font-size: 70% !important;
    }
  }

  a.nav-link__cta {
    font-size: 1em !important;

  }

  .collapse.show {
    @extend .mobile-menu;
  }

  .collapse {
    @extend .mobile-menu;
  }

  .collapsing {
    @extend .mobile-menu;
  }

  .navbar-toggler {
    box-shadow: 0 15px 35px rgba(50,50,93,.1), 0 5px 15px rgba(0,0,0,.07);
    border: 0px solid transparent !important;
  }
  .logo-image {
    background-color: white;
    border-radius: 100%;

    padding: 2%;
    width: 55px;
    height: 55px;
    margin-left: 0;
    display: table-cell;
    vertical-align: middle;
    text-align: center;



  }
  .logo-image > img {
    margin-top: 5px;
    margin-left: -3px;
  }
  .nav-bar {
    &.top-fixed {
      animation: none;
      position: fixed;
      top: 0;
      left: 0;
    }
  }

}

.navbar-toggler {
  background-color: white;
  z-index: 1000;
  border-radius: 100%;
  padding: 2%;
  width: 55px !important;
  height: 55px !important;
}

.navbar-toggler-icon {
  background-color: white !important;
  background: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='#0079BF' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E") !important;
}

